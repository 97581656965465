import React from 'react';

// https://integrate.spektrix.com/docs/resizingiframes
// In the Website Admin interface, set ‘Website Javascript Options’
// to point to a fully blank html file on your domain.
const SpektrixIntegration = () => (
  <div></div>
);

export default SpektrixIntegration;
